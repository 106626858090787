<template>
    <div class="page-content lg:p-6">
        <h3 class="text-center mb-5">Nueva circular</h3>
        <div class="field mb-4">
            <label for="title">Título</label>
            <InputText type="text" v-model="v$.title.$model" class="w-full mb-2" :class="{'p-invalid': v$.title.$error}" required />
            <div v-for="error of v$.title.$errors" :key="error.$uid">
                <small class="p-error">{{ validationMessages[error.$validator] }}</small>
            </div>
        </div>
        <div class="field mb-4">
            <label for="message">Mensaje</label>
            <Textarea v-model="v$.message.$model" :autoResize="true" class="w-full mb-2" rows="8" :class="{'p-invalid': v$.message.$error}" required />
            <div v-for="error of v$.message.$errors" :key="error.$uid">
                <small class="p-error">{{ validationMessages[error.$validator] }}</small>
            </div>
        </div>
        <div class="field mb-4">
            <label for="attachments[]">Archivos adjuntos (<strong>Máximo 20. La suma del tamaño de todos los archivos no puede exceder los 25MB.</strong>)</label>
            <FileUpload name="attachments[]" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Buscar archivo" :accept="$appState.acceptedMimetypes" :multiple="true" :fileLimit="20" invalidFileLimitMessage="Número máximo de archivos excedido. Solo se pueden subir {0} a la vez." />
            <div class="mt-4" v-if="attachmentsError"><p class="p-error font-bold">{{ attachmentsError }}</p></div>
        </div>
        <Button label="Enviar" icon="pi pi-send" :loading="working" class="p-button p-button-success mt-4" @click="sendForm" :disabled="canSendForm || working" />
        <div class="mt-4" v-if="sendError">
            <p class="p-error font-bold">{{ sendError }}</p>
            <ul v-if="serverErrors.length > 0">
                <li v-for="(serverError, i) in serverErrors" v-bind:key="i"><small class="p-error font-bold">{{ serverError }}</small></li>
            </ul>
        </div>
    </div>

    <Dialog v-model:visible="formSentDialog" :style="{width: '450px'}" header="Circular enviada" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <i class="pi pi-info-circle mr-3" style="font-size: 2rem" />
            <span>La circular se ha enviado correctamente.</span>
        </div>
        <template #footer>
            <Button label="Cerrar" icon="pi pi-check" class="p-button-text p-button-success" @click="formSentDialog = false" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import EstateService from '@/service/EstateService';
import ErrorReportService from '@/service/ErrorReportService.js';

const maxAttachmentsSize = 26210000;

export default {
    setup() {

        return { v$: useVuelidate() };
    },
    validations() {
        return {
            title: { required, minLength: minLength(3), maxLength: maxLength(255) },
            message: { required, minLength: minLength(3) },
        };
    },
    data() {
        return {
            working: false,
            validationMessages: {},
            sendError: null,
            serverErrors: [],
            attachmentsError: null,
            formSentDialog: false,
            title: '',
            message: '',
        };
    },
    estateService: null,
    errorReportService: null,
    selectedFiles: [],
    created() {
        this.estateService = new EstateService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
        this.$emit('updateBreadcrumbs', {label: 'Nueva circular', icon: 'mdi:text-account', to: '/circular', replace: false});
    },
    computed: {
        canSendForm() {
            let can = false;

            if (this.v$.title.$errors.length !== 0 || this.title.length < 3 || this.title.length > 255) {
                can = true;
            }
            if (this.v$.message.$errors.length !== 0 || this.message.length < 3) {
                can = true;
            }

            if (this.selectedFiles && this.selectedFiles.length > 0) {
                let totalSize = 0;

                this.selectedFiles.forEach(f => {
                    totalSize += f.size;
                });

                if (totalSize > maxAttachmentsSize) {
                    can = true;
                }
            }

            return can;
        },
    },
    methods: {
        sendForm() {
            if (!this.working) {
                this.working = true;
                this.sendError = null;
                this.serverErrors = [];
                let fd = new FormData();

                fd.append('title', this.title);
                fd.append('message', this.message);

                if (this.selectedFiles && this.selectedFiles.length > 0) {
                    this.selectedFiles.forEach((f, i) => {
                        fd.append(`attachments[${i}]`, f);
                    });
                }

                this.estateService.sendCircular(this.$appState.estate.id, fd)
                    .then(response => {
                        this.working = false;

                        if (response.data.sent === true) {
                            this.formSentDialog = true;
                        } else {
                            this.sendError = 'No se ha podido enviar la circular.';
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                title: this.title,
                                message: this.message,
                                files: this.selectedFiles.map(f => ({file_name: f.name, file_size: f.size}))
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.sendError = 'No se ha podido enviar la circular.';

                        if (error.response.data && error.response.data.errors) {
                            Object.keys(error.response.data.errors).forEach(ek => {
                                this.serverErrors.push(...error.response.data.errors[ek]);
                            });
                        }
                    });
            }
        },
        fileChanged(ev) {
            this.selectedFiles = ev.files;
            this.attachmentsError = null;
            let totalSize = 0;

            this.selectedFiles.forEach(f => {
                totalSize += f.size;
            });

            if (totalSize > maxAttachmentsSize) {
                this.attachmentsError = 'El tamaño total de los archivos supera los 25MB.';
            }
        }
    }
};
</script>